import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '../CardActionArea';
import Typography from '@material-ui/core/Typography';
import { miniCardWidth } from '../GameControlNavigator'
// import Img from 'gatsby-image';
import { GatsbyImage } from 'gatsby-plugin-image';
import { presentSellInfo, bgColor } from '../Game/SellBadge';

const LabeledBox = (props) => {
  const theme = useTheme();
  const style = {
    color: theme.palette.getContrastText(bgColor(theme, 'ee')[props.type]),
    backgroundColor: bgColor(theme, 'ee')[props.type],
  }
  return (
    <Box className={props.classes.label} style={style}>
      {props.children}
    </Box>
  )
}

export const oldImageToDisplay = (game) => {
  const gameForSaleInfo = game && game.game_for_sale && game.game_for_sale[0];
  if (gameForSaleInfo) {
    if (gameForSaleInfo.image && gameForSaleInfo.image.fluid) {
      return gameForSaleInfo.image.fluid;
    }
  }
  return game && game.image && game.image.fluid;
}


export const imageToDisplay = (game) => {
  const gameForSaleInfo = game && game.game_for_sale && game.game_for_sale[0];
  if (gameForSaleInfo) {
    if (gameForSaleInfo.image && gameForSaleInfo.image.gatsbyImageData) {
      return gameForSaleInfo.image.gatsbyImageData;
    }
  }
  return game && game.image && game.image.gatsbyImageData;
}


const GameImage = (props) => {
  const {
    game,
    comingSoon,
    commingSoonText,
    liveInfo,
    isForSale,
    type,
    classes: gridClasses,
  } = props;
  const classNameForGame = (!isForSale && comingSoon) ? 'comingGameImage' : 'availableGameImage';
  const image = imageToDisplay(game);

  return (
    <React.Fragment>
    {image &&
      <React.Fragment>
        <Box className={gridClasses.miniImageContainer}>
          <GatsbyImage image={image} alt={game.title} className={gridClasses[classNameForGame]} />
          <Box className={gridClasses.labels}>
          {!isForSale && comingSoon &&
            <LabeledBox classes={gridClasses} type={type} >
              {/* <RadarColorIcon className={gridClasses.labelIcon} color="primary" /> */}
              <Typography variant="body2" className={gridClasses.labelText}>{commingSoonText}</Typography>
            </LabeledBox>
          }
          {isForSale && liveInfo && liveInfo.saleInfo.inStock &&
            <React.Fragment>
              <LabeledBox classes={gridClasses} type={type}>
                {/* <NewBoxIcon className={gridClasses.boxIcon} /> */}
                <Typography variant="body2" className={gridClasses.labelText}>En Venta!!!</Typography>
              </LabeledBox>
            </React.Fragment>
          }
          {isForSale && liveInfo && liveInfo.saleInfo.preorderAvailable &&
            <React.Fragment>
              <LabeledBox classes={gridClasses} type={type} >
                {/* <NewRocketIcon className={gridClasses.boxIcon} height={50} width={50} /> */}
                <Typography variant="body2" className={gridClasses.labelText}>Pre Venta!!!</Typography>
              </LabeledBox>
            </React.Fragment>
          }
          {isForSale && liveInfo && !liveInfo.saleInfo.inStock && !liveInfo.saleInfo.preorderAvailable &&
            <React.Fragment>
              <LabeledBox classes={gridClasses} type={type}>
                {/* <NotificationIcon className={gridClasses.boxIcon} height={50} width={50} /> */}
                <Typography variant="body2" className={gridClasses.labelText}>Avisame!!!</Typography>
              </LabeledBox>
            </React.Fragment>
          }          
          </Box>

        </Box>
      </React.Fragment>
    }
    </React.Fragment>

  )
}

export const GameCard = (props) => {
  const [navigationEnabled, setNavigation] = useState(false);
  const [liveInfo, setLiveInfo] = useState();
  const [gameType, setGameType] = useState('forPlay');
  const { 
    game,
    city,
    gameForSaleInfo, 
    liveInfoLoaded, 
    classes: gridClasses, 
  } = props;
  const metadata = game.game_for_sale && game.game_for_sale[0];

  const comingSoon = (!game.available && game.available === false) ? 'próximamente': '';
  const isForSale = (!!game.game_for_sale);
  const comingSoonText = (comingSoon) ? 'próximamente': '';
  const title = (isForSale) ? game.game_for_sale[0].title : game.title;
  useEffect(() => {
    setNavigation(true);
  })
  useEffect(() => {
    if (liveInfoLoaded) {
      const info = presentSellInfo(game, gameForSaleInfo);
      setLiveInfo(info);
    } 
  }, [liveInfoLoaded])
  useEffect(() => {
    if (liveInfo && liveInfo.type) {
      setGameType(liveInfo.type);
    }
  },[liveInfo]);

  const gameOrExpansion = (game.expansion) ? 'expansion' : 'juego'
  return (<Card className={gridClasses.miniCard}>
  {/* <CardActionArea disabled={!navigationEnabled}  to={`/${city}/${gameOrExpansion}/${game.id}/${game.slug}`} > */}
  <CardActionArea disabled={!navigationEnabled}  to={`/ba/${gameOrExpansion}/${game.id}/${game.slug}`} >
    <GameImage
      {...props}
      comingSoon={comingSoon}
      comingSoonText={comingSoonText}
      liveInfo={liveInfo}
      isForSale={isForSale}
      type={gameType}
      classes={gridClasses}
    />
    </CardActionArea>
  </Card>
  )

}

export const VirtuosoCardGenerator = (db, props) => (key, index) => {
  const game = db[key];
  if (!game) return null;
  const {
    gridOptions,
    gameForSaleInfo,
    city,
    liveInfoLoaded,
    gridClasses,
  } = props;
  
  const useBase64Image = (game.image.fluid.base64 !== undefined);

  return (
    <Grid key={game.id} item xs={gridOptions.xs} sm={gridOptions.sm} md={gridOptions.md} lg={gridOptions.lg} xl={gridOptions.xl}>

      <GameCard 
        game={game}
        city={city}
        gameForSaleInfo={gameForSaleInfo}
        liveInfoLoaded={liveInfoLoaded}
        classes={gridClasses}
        useBase64Image={useBase64Image}
      />
    </Grid>
    )
}

export default GameCard;
